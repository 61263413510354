import React from "react"
import CalEventLocation from "./caleventlocation"
import { eventUrl } from "../../utils/urls"

import HTMLDiv from "../htmldiv"
import useSiteMetadata from "../../hooks/sitemetadata"
import useCalEventType from "../../hooks/caleventype"
import useCalEventTypeUrl from "./caleventtypeurl"

import FullDiv from "../fulldiv"
import HideSmall from "../hidesmall"
import ZoomImage from "../images/zoomimage"
import Row from "../row"
import { Link } from "gatsby"
import IndexLink from "../buttons/indexlink"

export const useImageName = (event: any): string => {
  let imageName: string = ""

  for (let tag of event.frontmatter.tagList) {
    if (tag.startsWith("image::")) {
      const tokens = tag.split("::")
      imageName = tokens[1]
      break
    }
  }

  return imageName
}

type EventImageProps = {
  name: string
  imageMap: any
  size?: number
  hover?: boolean
}

export const EventImage: React.FC<EventImageProps> = ({
  name,
  imageMap,
  hover,
}) => (
  <div className={`w-full h-full`}>
    {name in imageMap && (
      <ZoomImage
        image={imageMap[name]}
        className="w-full h-full"
        extZoom={hover}
      />
    )}
  </div>
)

EventImage.defaultProps = {
  hover: false,
  size: 48,
}

type CalEventDetailsProps = {
  event: any
  isMobile?: boolean
  color?: string
  imageMap?: any
  hover?: boolean
}

const CalEventDetails: React.FC<CalEventDetailsProps> = ({
  event,
  isMobile,
  color,
  imageMap,
  hover,
}) => {
  const { paths } = useSiteMetadata()

  const eventType = useCalEventType(event)

  let eventLink
  let addLink

  switch (eventType) {
    case "Public Talk":
    case "Guest Speaker":
      eventLink = (
        <Link to={eventUrl(event)}>
          <div className="text-red-400">{event.frontmatter.title}</div>
        </Link>
      )

      addLink = (
        <IndexLink color="red" to={event.icsFile}>
          Add To Calendar
        </IndexLink>
      )
      break
    default:
      eventLink = (
        <Link to={eventUrl(event)}>
          <div className="text-columbia-secondary-blue">
            {event.frontmatter.title}
          </div>
        </Link>
      )

      addLink = (
        <IndexLink color="blue" to={event.icsFile}>
          Add To Calendar
        </IndexLink>
      )
      break
  }

  // See if we should include an image
  const imageName = useImageName(event)

  return (
    <Row isVCentered={false} className="justify-between">
      <FullDiv>
        <div className="mb-2">
          <h2>{eventLink}</h2>
          <ul className="mt-3">
            {event.frontmatter.tagList.map((t: any, index: number) => {
              return (
                <li
                  className={`text-sm font-medium bg-cuimc-gray rounded px-3 py-1 mt-2 inline ${
                    index > 0 ? "ml-3" : ""
                  }`}
                >
                  <Link to={useCalEventTypeUrl(paths.eventsPath, t)}>{t}</Link>
                </li>
              )
            })}
          </ul>

          <div
            className={`mt-4 text-lg ${color === "white" ? "text-white" : ""}`}
          >
            <HTMLDiv o={event} />
          </div>
        </div>

        <CalEventLocation event={event} color={color} />

        <div className="text-sm mt-4">{addLink}</div>
      </FullDiv>
      <HideSmall size="lg" className="pt-2 md:pt-0 min-w-56 overflow-hidden">
        <EventImage name={imageName} imageMap={imageMap} hover={hover} />
      </HideSmall>
    </Row>
  )
}

export default CalEventDetails

CalEventDetails.defaultProps = {
  isMobile: false,
  color: "black",
  imageMap: {},
  hover: false,
}
