import React, { useState } from "react"
import FullDiv from "../fulldiv"
import Row from "../row"
import Day from "../calendar/day"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import ColorLink from "../buttons/colorlink"
import HideSmall from "../hidesmall"
import ShowSmall from "../showsmall"
import SmallDay from "../calendar/smallday"
import dayjs from "dayjs"
import { EventListItem } from "../calendar/calevents"
import { eventDate } from "../calendar/calevent2"

type LectureProps = {
  lecture: any
  index: number
}
const Lecture2: React.FC<LectureProps> = ({ lecture, index }) => {
  const [hover, setHover] = useState(false)

  const onMouseEnter = (e: any) => {
    setHover(true)
  }

  const onMouseLeave = (e: any) => {
    setHover(false)
  }

  const start = dayjs(lecture.start)
  const end = dayjs(lecture.end)

  // const day = {
  //   month: date.toLocaleString("default", {
  //     month: "short",
  //   }),
  //   day: date.toLocaleString("default", {
  //     day: "numeric",
  //   }),
  //   weekday: date.toLocaleString("default", {
  //     weekday: "short",
  //   }),
  // }

  const files = lecture.files.map((file: string, index: number) => {
    const tokens = file.split("::")

    let url
    let title

    if (tokens.length > 1) {
      url = tokens[1]
    } else {
      url = tokens[0]
    }

    const name = url.split(/\//).pop()
    const ext = name.split(/\./).pop()

    if (tokens.length > 1) {
      title = tokens[0]
    } else {
      switch (ext) {
        case "pdf":
          title = "PDF handout"
          break
        case "ppt":
        case "pptx":
          title = "PowerPoint slides"
          break
        default:
          title = "Download slides"
          break
      }
    }

    let icon

    switch (ext) {
      case "pdf":
        icon = (
          <FontAwesomeIcon
            icon={["far", "file-pdf"]}
            className="text-xl text-red-500"
          />
        )
        break
      case "ppt":
      case "pptx":
        icon = (
          <FontAwesomeIcon
            icon={["far", "file-powerpoint"]}
            className="text-xl text-orange-500"
          />
        )
        break
      default:
        icon = <FontAwesomeIcon icon={["far", "file"]} className="text-xl" />
        break
    }

    return (
      <li className="inline-block my-2 mr-8" key={index}>
        <Row className="text-sm">
          <div className="mr-2">{icon}</div>
          <div>
            <ColorLink color="blue" to={url}>
              {title}
            </ColorLink>
          </div>
        </Row>
      </li>
    )
  })

  return (
    <EventListItem index={index}>
      {eventDate(start, end)}

      <h2 className="text-green-500 mt-4">{lecture.title}</h2>
      <div className="mt-3 font-light">{lecture.location}</div>
      <h4 className="mt-4">{lecture.lecturer}</h4>

      {files.length > 0 && (
        <ul className="inline-block mt-2">
          {/* <h6 className="font-medium">Downloads</h6> */}
          {files}
        </ul>
      )}
    </EventListItem>
  )
}

export default Lecture2
