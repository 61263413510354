import React, { useState } from "react"
import { EventListItem, EventMonthItem } from "../calendar/calevents"
import Lecture2 from "./lecture2"

type CourseSectionProps = {
  section: any
}
const CourseSection: React.FC<CourseSectionProps> = ({ section }) => {
  const [hover, setHover] = useState(false)

  const onMouseEnter = (e: any) => {
    setHover(true)
  }

  const onMouseLeave = (e: any) => {
    setHover(false)
  }

  if (!section.title.toLowerCase().includes("test")) {
    return (
      <li>
        <EventMonthItem>{section.title}</EventMonthItem>
        <ul>
          {section.lectures.map((lecture: any, index: number) => {
            return <Lecture2 lecture={lecture} key={index} index={index} />
          })}
        </ul>
      </li>
    )
  } else {
    return (
      <li className="my-16 p-4 border border-solid border-gray-300 rounded-md bg-gray-100">
        <h5 className="text-center uppercase font-medium text-gray-700">
          {section.title}
        </h5>
      </li>
    )
  }
}

export default CourseSection
