import React, { useState, useEffect } from "react"
import FlHdDiv from "../components/flhddiv"
import Row from "../components/row"
import useBooleanSearch from "../hooks/booleansearch"
import useBreakpoints from "../hooks/breakpoints"
import CourseSection from "../components/courses/coursesection"
import { graphql } from "gatsby"
import Container from "../components/container"
import BlueButton from "../components/buttons/bluebutton"
import VertTabs from "../components/tabs/verttabs"
import PageLayout from "../layouts/pagelayout"

const EMPTY_QUERY = ""

const RECORDS_PER_PAGE = 25

export const search = (query: any, courses: Array<any>): Array<any> => {
  let ret: any = []

  let ql = query.text.toLowerCase()

  for (let course of courses) {
    let found = false

    found = course.title.toLowerCase().includes(ql)

    if (found) {
      ret.push(course)
    }
  }

  return ret
}

const booleanSearchAnd = (s1: any, s2: any): any => {
  const titles: Set<any> = new Set()

  s2.map((course: any) => {
    titles.add(course.title)
  })

  return s1.filter((course: any) => {
    return titles.has(course.title)
  })
}

const booleanSearchOr = (s1: any, s2: any): any => {
  const pubMap: any = {}

  s1.map((course: any) => {
    if (!(course.title in pubMap)) {
      pubMap[course.title] = course
    }
  })

  s2.map((course: any) => {
    if (!(course.title in pubMap)) {
      pubMap[course.title] = course
    }
  })

  const ret = []

  for (let title of Object.keys(pubMap).sort()) {
    ret.push(pubMap[title])
  }

  return ret
}

export const useCourses = (data: any) => {
  const ret = []

  for (const { node } of data.courses.edges) {
    const course = node

    ret.push(course)
  }

  return ret
}

type CoursesTemplateProps = {
  path: string
  pageContext: any
  data: any
}

const CoursesTemplate: React.FC<CoursesTemplateProps> = ({
  path,
  pageContext,
  data,
}) => {
  const { crumbs } = pageContext

  const [query, setQuery] = useState<string>(EMPTY_QUERY)

  const breakpoint = useBreakpoints()

  const allCourses: Array<any> = useCourses(data)

  const [filteredCourses, setFilteredCourses] = useState<Array<any>>(allCourses)

  const [page, setPage] = useState<number>(1)

  const [recordsPerPage, setRecordsPerPage] = useState(RECORDS_PER_PAGE)

  useEffect(() => {
    if (query !== "") {
      updateFilteredCourses(
        useBooleanSearch(
          query,
          allCourses,
          search,
          booleanSearchAnd,
          booleanSearchOr
        )
      )
    } else {
      updateFilteredCourses(allCourses)
    }

    setPage(1)
    setRecordsPerPage(RECORDS_PER_PAGE)
  }, [query])

  const updateFilteredCourses = (courses: Array<any>) => {
    setFilteredCourses(courses)
  }

  const handleSearch = (text: string, clicked: boolean) => {
    if (clicked) {
      setQuery(text)
    }
  }

  const handlePerPageChange = (value: string) => {
    setRecordsPerPage(parseInt(value))
  }

  const onPageChanged = (data: any) => {
    const { page } = data
    setPage(page)
  }

  // const handleClick = (data: any) => {
  //   setFilterYears(data)
  //   setPage(1)
  // }

  const handleShowMoreClick = (e: any) => {
    setRecordsPerPage(2 * recordsPerPage)
  }

  const course = filteredCourses[0]

  const title = course.title

  return (
    <PageLayout title={title} nav={"Courses"} path={path} crumbs={crumbs}>
      <FlHdDiv>
        <Container>
          {/* <MainSideCol> */}
          <VertTabs tab={1}>
            <div id="2020">
              {/* <h5>{course.directors}</h5> */}

              <div>
                <h2>{course.title}</h2>
                <ul>
                  {course.details.map((d: any, index: number) => {
                    return (
                      <li key={index}>
                        <h5>{d}</h5>
                      </li>
                    )
                  })}
                </ul>
              </div>

              <ul className="mt-8">
                {course.sections.map((section: any, index: number) => {
                  return <CourseSection section={section} key={index} />
                })}
              </ul>

              {recordsPerPage < filteredCourses.length && (
                <Row isCentered={true} className="mt-8">
                  <div>
                    <BlueButton onClick={handleShowMoreClick}>
                      More courses
                    </BlueButton>
                  </div>
                </Row>
              )}

              {/* <div className="mt-16 mb-8">
                <Pagination
                  page={page}
                  pageNeighbors={1}
                  totalRecords={filteredPublications.length}
                  recordsPerPage={recordsPerPage}
                  onPageChanged={onPageChanged}
                />
              </div> */}
            </div>
            <div id="2021">
              <h3>Courses for 2021 will appear later in the year.</h3>
            </div>
          </VertTabs>
          <></>
          {/* </MainSideCol> */}
        </Container>
      </FlHdDiv>
    </PageLayout>
  )
}

export default CoursesTemplate

export const query = graphql`
  query {
    courses: allCoursesJson {
      edges {
        node {
          id
          title
          directors
          details
          sections {
            title
            lectures {
              title
              lecturer
              start
              end
              startTime: start(formatString: "h:mm")
              endTime: end(formatString: "h:mm a")
              location
              files
            }
          }
        }
      }
    }
  }
`
