import { MDXRenderer } from "gatsby-plugin-mdx"
import React from "react"

type HTMLDivProps = {
  o: any
  excerpt?: boolean
}

/**
 * Show HTML inside a div
 *
 * @param {any} o   An ojbect with either html or body members.
 */
const HTMLDiv: React.FC<HTMLDivProps> = ({ o, excerpt }) => {
  if (o !== undefined && o !== null) {
    if (excerpt) {
      if (o.excerpt !== undefined) {
        return <MDXRenderer>{o.excerpt}</MDXRenderer>
      } else {
        return <div dangerouslySetInnerHTML={{ __html: o.excerpt }} />
      }
    } else {
      if (o.body !== undefined && o.body !== null) {
        return <MDXRenderer>{o.body}</MDXRenderer>
      } else {
        return <div dangerouslySetInnerHTML={{ __html: o.html }} />
      }
    }
  } else {
    return <></>
  }
}

HTMLDiv.defaultProps = {
  excerpt: false,
}

export default HTMLDiv
